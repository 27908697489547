<template>
    <base-bottom-sheet
        v-if="currentUserData"
        v-model="isCreateInvoiceFromTimeregistrationWizardOpen"
        transition="scale-transition"
        inset
        retain-focus
        scrollable
        eager
    >
        <template #header>
            <bottom-sheet-header>
                {{ $t('general.create_invoice_from_timeregistrations') }}
            </bottom-sheet-header>
        </template>
        <template #content>
            <v-form ref="form" v-model="valid">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="4" class="pt-0 pb-0">
                            <v-select
                                v-model="invoiceRequest.projectIds"
                                :items="projects"
                                :label="$t('timeregistrationToInvoiceWizard.select_projects_with_timeregistrations')"
                                multiple
                                required
                                outlined
                                dense
                                item-text="name"
                                item-value="id"
                                persistent-hint
                                :no-data-text="$t('general.no_projects_available')"
                                :rules="requiredRules"
                            ></v-select>
                        </v-col>
                        <v-col v-if="false" cols="12" md="4" class="pt-0 pb-0">
                            <v-select
                                v-model="invoiceRequest.unitMode"
                                :items="unitModes"
                                :label="$t('general.unit_mode')"
                                outlined
                                disabled
                                dense
                                item-text="label"
                                item-value="value"
                                required
                                persistent-hint
                                :rules="requiredRules"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4" class="pt-0 pb-0">
                            <v-select
                                v-model="invoiceRequest.itemMode"
                                :items="itemModes"
                                :label="$t('general.item_mode')"
                                outlined
                                dense
                                item-text="label"
                                item-value="value"
                                required
                                persistent-hint
                                :rules="requiredRules"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4" class="pt-0 pb-0">
                            <v-select
                                v-model="selectedTimeRangeMode"
                                :items="timeRangeMode"
                                :label="$t('timeregistrationToInvoiceWizard.range')"
                                outlined
                                dense
                                item-text="label"
                                required
                                item-value="value"
                                persistent-hint
                                :rules="requiredRules"
                            ></v-select>
                        </v-col>

                        <template v-if="selectedTimeRangeMode === TIME_RANGE_CUSTOM">
                            <v-col cols="12" md="6" class="pt-0 pb-0">
                                <date-field
                                    v-model="invoiceRequest.beginDateRange"
                                    :label="$t('timeRegistration.start')"
                                    outlined
                                    clearable
                                    persistent-hint
                                    required
                                />
                            </v-col>
                            <v-col cols="12" md="6" class="pt-0 pb-0">
                                <date-field
                                    v-model="invoiceRequest.endDateRange"
                                    :label="$t('timeRegistration.end')"
                                    outlined
                                    clearable
                                    persistent-hint
                                    required
                                />
                            </v-col>
                        </template>
                        <v-col cols="12" md="12" class="mt-3">
                            <v-btn color="primary" small rounded :disabled="!valid" @click="handleSubmit">
                                {{ $t('general.generate_drafts') }}
                                <v-icon right dense dark>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>

            <loader v-if="loading"></loader>
        </template>
    </base-bottom-sheet>
</template>

<script>
// --- State ---
import { mapState, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
// --- Components ---
import BottomSheetHeader from './BottomSheetHeader';
import Loader from '../Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- helpers ---
// --- Helpers ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';

import _ from 'lodash';

const TIME_RANGE_ALL = 0;
const TIME_RANGE_CUSTOM = 1;

export default {
    components: {
        BottomSheetHeader,
        Loader
    },

    mixins: [formRulesMixin],

    data() {
        return {
            valid: true,
            loading: false,
            customer: null,
            TIME_RANGE_ALL: TIME_RANGE_ALL,
            TIME_RANGE_CUSTOM: TIME_RANGE_CUSTOM,
            projects: [],
            unitModes: [
                {
                    label: this.$t('unitModes.minutes'),
                    value: 0
                },
                {
                    label: this.$t('unitModes.hours'),
                    value: 1
                }
            ],
            itemModes: [
                {
                    label: this.$t('itemModes.ONE_LINE'),
                    value: 0
                },
                {
                    label: this.$t('itemModes.DAY'),
                    value: 1
                }
            ],
            invoiceRequest: {
                projectIds: null,
                beginDateRange: null,
                endDateRange: null,
                itemMode: null,
                unitMode: 1
            },
            projectIds: [],
            selectedTimeRangeMode: null,
            timeRangeMode: [
                {
                    label: this.$t('timeRangeMode.all'),
                    value: TIME_RANGE_ALL
                },
                {
                    label: this.$t('timeRangeMode.custom'),
                    value: TIME_RANGE_CUSTOM
                }
            ]
        };
    },
    computed: {
        ...mapFields('bottomSheets', ['isCreateInvoiceFromTimeregistrationWizardOpen']),
        ...mapState('auth', ['currentUserData'])
    },

    watch: {
        isCreateInvoiceFromTimeregistrationWizardOpen() {
            this.fetchProjects();
        },
        selectedTimeRangeMode(value) {
            if (value === TIME_RANGE_ALL) {
                this.invoiceRequest.beginDateRange = null;
                this.invoiceRequest.endDateRange = null;
            }
        }
    },
    created() {
        this.loading = true;
        this.fetchProjects();
    },
    methods: {
        ...mapActions('projects', [
            'createProject',
            'updateProject',
            'getActiveProjectsWithTimeregistrations',
            'generateInvoicePerProject'
        ]),
        ...mapActions('timeregistrations', ['newTimeregistrationEvent']),
        resetForm() {
            this.$refs.form.reset();
        },
        fetchProjects() {
            this.getActiveProjectsWithTimeregistrations()
                .then((projects) => {
                    this.projects = projects;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async handleSubmit() {
            const invoiceRequestCopy = _.cloneDeep(this.invoiceRequest);

            try {
                this.loading = true;

                await this.generateInvoicePerProject(invoiceRequestCopy);

                notify.call(this, {
                    title: this.$t('addProject.invoices_succesfuly_created'),
                    text: this.$t('addProject.invoices_succesfuly_created')
                });

                this.newTimeregistrationEvent();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
