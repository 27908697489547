<template>
    <v-chip
        class="Chip mr-1"
        label
        :large="size === 'large'"
        :small="size === 'small'"
        :color="color"
        :text-color="textColor"
        :class="{ medium: size === 'medium' }"
    >
        <slot />
    </v-chip>
</template>

<script>
export default {
    props: {
        textColor: {
            type: String,
            default: 'white'
        },
        color: {
            type: String,
            default: 'primary'
        },
        size: {
            type: String,
            default: 'small'
        }
    }
};
</script>

<style lang="scss" scoped>
.Chip.medium {
    height: 44px;
    padding: 0 19.5555555556px;
    min-width: 78px;
}
</style>
