<template>
    <div data-id="ProjectTable" class="billed_income-table">
        <v-row class="filtering-container">
            <v-col cols="12" md="3">
                <v-select
                    v-model="query.status"
                    :items="statusOptions"
                    :label="$t('general.status')"
                    item-text="label"
                    item-value="value"
                    clearable
                    hide-details
                    dense
                    outlined
                />
            </v-col>
            <v-col cols="12" md="9">
                <pagination-search v-model="query.q" />
            </v-col>
        </v-row>
        <pagination-data-table
            v-if="projects.data"
            ref="paginationDataTable"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="projects"
            sort-by="date"
            :loading="loading"
            :class="{ clickableFirstItem: clickable }"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
        >
            <template #[`item.hourlyRateEuro`]="{ item }">{{
                item.hourlyRateEuro | numericFormat | addEuroSign
            }}</template>
            <template #[`item.total_hours`]="{ item }">{{ getTotalHours(item) | numericFormat }} uur </template>
            <template #[`item.total`]="{ item }">{{ getTotal(item) | numericFormat | addEuroSign }} </template>
            <template #[`item.unpayed`]="{ item }"> {{ getUnpaid(item) | numericFormat | addEuroSign }}</template>
            <template #[`item.status`]="{ item }">
                <chip v-if="!item.closed" text-color="white">
                    {{ $t('general.active') }}
                </chip>
                <chip v-if="item.closed" text-color="white">
                    {{ $t('general.inactive') }}
                </chip></template
            >

            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item @click="editProject(item)">
                                <v-list-item-title>{{ $t('general.edit') }}</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="toggleInactive(item)">
                                <v-list-item-title>{{
                                    item.closed ? $t('general.put_on_active') : $t('general.put_on_inactive')
                                }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                :disabled="item.timeregistrations.length !== 0"
                                @click="
                                    dialog = true;
                                    selectedProject = item.id;
                                "
                            >
                                <v-list-item-title>{{ $t('general.delete') }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu></template
            >
        </pagination-data-table>

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            :title="$t('general.delete')"
            :message="$t('general.confirm_delete_message')"
            :loading="loading"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="removeProject"
            @click-negative="dialog = false"
        />
    </div>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';
import PaginationSearch from '../PaginationSearch.vue';
// --- Helper ---
import { mapFields } from 'vuex-map-fields';
import { notify } from '@/helpers/successNotification';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { filteringMixin } from '../../mixins/filtering';
import PaginationDataTable from './PaginationDataTable.vue';
import Chip from '../Chip.vue';
import { clickableRowTableMixin } from '../../mixins/clickableRowTableMixin';

import { calculateNumberOfMinutes } from '@/helpers/timeregistrationCalculation';

import _ from 'lodash';

export default {
    components: {
        ConfirmationDialog,
        PaginationSearch,
        PaginationDataTable,
        Chip
    },
    mixins: [filteringMixin, clickableRowTableMixin],

    props: {
        inputStatus: {
            required: false,
            type: String
        },

        clickable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const sortableColumns = ['name', 'hourlyRateEuro'];

        return {
            headers: [
                {
                    text: this.$t('projectTable.name'),
                    align: 'start',
                    value: 'name',
                    sortable: sortableColumns.includes('name')
                },
                {
                    text: this.$t('general.customer_name'),
                    value: 'customer.company.name',
                    sortable: sortableColumns.includes('customer.company.name')
                },
                {
                    text: this.$t('general.total_hours'),
                    value: 'total_hours',
                    sortable: sortableColumns.includes('total_hours')
                },
                {
                    text: this.$t('general.total'),
                    value: 'total',
                    sortable: sortableColumns.includes('total')
                },
                {
                    text: this.$t('projects.outstanding'),
                    value: 'unpayed',
                    sortable: sortableColumns.includes('unpayed')
                },
                {
                    text: this.$t('addProject.hourlyRateEuro'),
                    value: 'hourlyRateEuro',
                    sortable: sortableColumns.includes('hourlyRateEuro')
                },
                {
                    text: this.$t('general.status'),
                    value: 'status',
                    sortable: sortableColumns.includes('status')
                },
                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            dialog: false,
            loading: false,
            toDeleteUid: '',
            selectedProject: '',
            projects: {
                data: []
            },
            query: {
                status: this.inputStatus || null
            },
            statusOptions: [
                {
                    label: this.$t('general.active'),
                    value: 'active'
                },
                {
                    label: this.$t('general.inactive'),
                    value: 'inactive'
                }
            ],
            sortableColumns
        };
    },

    created() {
        this.wrapperFunction();
    },

    computed: {
        ...mapFields('bottomSheets', ['isAddProjectOpen', 'currentProject', 'isCreatingNewProject']),
        ...mapState('projects', ['newProjectEvents'])
    },

    watch: {
        newProjectEvents() {
            this.wrapperFunction();
        }
    },

    methods: {
        ...mapActions('bottomSheets', ['editProject', 'newProject']),
        ...mapActions('projects', ['deleteProject', 'getAllProjectsPagination', 'toggleProjectClosed']),
        async wrapperFunction() {
            this.loading = true;
            this.projects = await this.getAllProjectsPagination({
                pagination: {
                    force: true,
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit
                },
                query: this.query
            });
            this.loading = false;
        },
        async toggleInactive(project) {
            this.loading = true;
            try {
                await this.toggleProjectClosed(project.id);

                notify.call(this, {
                    title: this.$t('projects.succesfuly_updated'),
                    text: this.$t('projects.succesfuly_updated')
                });
                this.wrapperFunction();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
                this.dialog = false;
            }
        },
        async removeProject() {
            this.loading = true;
            try {
                await this.deleteProject(this.selectedProject);

                notify.call(this, {
                    title: this.$t('projects.succesfuly_deleted'),
                    text: this.$t('projects.succesfuly_deleted')
                });
                this.wrapperFunction();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
                this.dialog = false;
            }
        },
        getTotal(project) {
            return (
                _.sumBy(project.timeregistrations, (timeregistration) => {
                    return (
                        (calculateNumberOfMinutes(timeregistration.start, timeregistration.end) / 60) *
                        project.hourlyRateEuro
                    );
                }) || 0
            );
        },
        getTotalHours(project) {
            return (
                _.sumBy(project.timeregistrations, (timeregistration) => {
                    return calculateNumberOfMinutes(timeregistration.start, timeregistration.end) / 60;
                }) || 0
            );
        },
        getUnpaid(project) {
            return (
                _.sumBy(
                    project.timeregistrations.filter((_timeregistration) => _timeregistration.end !== null),
                    (timeregistration) => {
                        if (timeregistration.invoiceId === null) {
                            return (
                                (calculateNumberOfMinutes(timeregistration.start, timeregistration.end) / 60) *
                                project.hourlyRateEuro
                            );
                        }
                    }
                ) || 0
            );
        }
    }
};
</script>
