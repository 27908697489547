<template>
    <v-data-table
        :id="id"
        data-id="paginationTable"
        :headers="headers"
        :group-by="groupBy"
        :items="paginationData.data"
        :loading="loading"
        :items-per-page.sync="pagination.limit"
        :footer-props="{
            'items-per-page-options': pagination.itemsPerPageOptions
        }"
        :page.sync="pagination.currentPage"
        :server-items-length="paginationData.total"
        :sort-by="sortBy"
        sort-desc
        :item-class="renderRowID"
        :show-select="showSelect"
        v-bind="$attrs"
        class="pagination-table"
        @input="handleSelectedInputs"
        @update:sort-by="emitSortByChange"
        @update:sort-desc="emitSortDescChange"
        @click:row="handleClickRow"
    >
        <!-- pass through scoped slots -->
        <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
    </v-data-table>
</template>

<script>
export default {
    props: {
        headers: {
            type: Array,
            required: true
        },
        paginationData: {
            type: Object,
            required: true
        },
        pagination: {
            type: Object,
            required: true
        },
        sortBy: {
            type: String,
            required: false
        },
        renderClassFunction: {
            required: false
        },
        showSelect: {
            default: false,
            type: Boolean
        },
        id: {
            type: String,
            required: false
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        groupBy: {
            type: String,
            default: null
        },
        topPagination: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    watch: {
        'pagination.currentPage'() {
            this.wrapperFunction();
        },
        'pagination.limit'() {
            this.wrapperFunction();
        }
    },
    methods: {
        renderRowID(item) {
            const classes = [`id-${item.id.toString()}`];
            if (this.renderClassFunction) {
                classes.push(this.renderClassFunction(item));
            }
            return classes;
        },
        wrapperFunction() {
            this.$emit('refetch-data', {
                currentPage: this.pagination.currentPage,
                limit: this.pagination.limit
            });
        },
        emitSortByChange(newValue) {
            this.$emit('sort-by', newValue);
        },
        emitSortDescChange(newValue) {
            this.$emit('sort-desc', newValue);
        },
        handleNext() {
            this.pagination.currentPage = this.pagination.currentPage + 1;
        },
        handlePrevious() {
            this.pagination.currentPage = this.pagination.currentPage - 1;
        },
        handleClickRow(item) {
            this.$emit('click-row', item);
        },
        handleSelectedInputs(items) {
            this.$emit('selected-items', items);
        }
    }
};
</script>

<style lang="scss" scoped>
.pagination-top-wrapper {
    display: flex;
    align-items: center;
}

.datatable-top-pagination :deep() {
    ul {
        justify-content: flex-end !important;
    }
}

.pagination-table :deep() {
    & > .v-data-table__wrapper > table > thead > tr > th:last-child,
    &
        > .v-data-table__wrapper
        > table
        > tbody
        > tr:not(.v-data-table__empty-wrapper):not(.v-row-group__header)
        > td:last-child {
        text-align: right !important;
    }

    & > .v-data-table__wrapper > table > tbody > tr > td:last-child > button.v-btn--icon {
        color: $dexxter-purple !important;
    }

    & > .v-data-footer .v-data-footer__select {
        margin-right: auto !important;
    }
}
</style>
