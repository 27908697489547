<template>
    <div class="SearchInput" @click="handleClickedSearch">
        <text-field outlined readonly class="text-field" :label="label" dense hide-details="auto" :value="value" />
        <v-icon color="primary" class="icon" data-action="open-table-search" @click="handleClickedSearch">
            mdi-magnify
        </v-icon>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number],
            default: ''
        }
    },

    methods: {
        handleClickedSearch() {
            this.$emit('clicked-search');
        }
    }
};
</script>

<style lang="scss" scoped>
.SearchInput {
    display: flex;
    align-items: center;
}

.text-field {
    cursor: pointer !important;
}
.icon {
    height: 44px;
    width: 44px;
}
</style>
