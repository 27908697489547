<template>
    <base-bottom-sheet
        v-if="currentTimeRegistrationLocal && isAddTimeRegistrationOpen"
        v-model="isAddTimeRegistrationOpen"
        transition="scale-transition"
        inset
        retain-focus
        scrollable
        eager
    >
        <template #header>
            <bottom-sheet-header>
                {{ $t('addTimeRegistration.title_edit') }}
            </bottom-sheet-header>
        </template>
        <template #content>
            <v-form ref="form" v-model="valid">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6">
                            <text-field
                                v-model="currentTimeRegistrationLocal.description"
                                name="projectDescription"
                                :label="$t('timeRegistration.form.description')"
                                hide-details="auto"
                                outlined
                                dense
                                :rules="nameRules"
                            ></text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <date-field
                                v-model="currentTimeRegistrationLocal.date"
                                :label="$t('general.date')"
                                outlined
                                dense
                                persistent-hint
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <text-field
                                v-model="currentTimeRegistrationLocal.start"
                                v-mask="'##:##'"
                                name="duration"
                                :label="$t('timeRegistration.start')"
                                type="text"
                                outlined
                                dense
                                :rules="timeRangeRules"
                            ></text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <text-field
                                v-model="currentTimeRegistrationLocal.end"
                                v-mask="'##:##'"
                                name="duration"
                                :label="$t('timeRegistration.end')"
                                type="text"
                                outlined
                                dense
                                :rules="[...timeRangeRules, endGreaterThanStart]"
                            ></text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <search-input
                                :label="$t('timeRegistration.form.project')"
                                :value="
                                    currentTimeRegistrationLocal.project
                                        ? currentTimeRegistrationLocal.project.name
                                        : ''
                                "
                                @clicked-search="searchProject"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-btn color="primary" small rounded :disabled="!valid" @click="handleSubmit"
                                >{{ $t('timeRegistration.form.save') }}
                                <v-icon right dense dark>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <loader v-if="loading" />
            <select-table-component
                ref="selectTableComponent"
                :title="$t('general.select_project')"
                :input-component="projectsTable"
                @selected-item="handleSelectedProject"
                @new-item="openCreateProjectModal"
            />
        </template>
    </base-bottom-sheet>
</template>

<script>
// --- Modules ---
// --- State ---
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
// --- Components ---
import BottomSheetHeader from './BottomSheetHeader';
import SelectTableComponent from '../SelectTableComponent.vue';
import SearchInput from '../SearchInput.vue';
import Loader from '../Loader';
import ProjectsTable from '../DataTables/ProjectsTable.vue';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- Helpers ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { timeWithoutMask } from '@/helpers/timeregistration';
import dayjs from 'dayjs';
import _ from 'lodash';

export default {
    components: {
        BottomSheetHeader,
        Loader,
        SearchInput,
        SelectTableComponent
    },

    mixins: [formRulesMixin],

    data() {
        return {
            valid: true,
            loading: false,
            isNotSameDay: false,
            currentTimeRegistrationLocal: null,
            projectsTable: ProjectsTable
        };
    },

    computed: {
        ...mapFields('bottomSheets', [
            'isAddTimeRegistrationOpen',
            'currentTimeRegistration',
            'isAddProjectOpen',
            'isCreatingNewProject'
        ])
    },

    watch: {
        currentTimeRegistration: {
            immediate: true,
            handler(newTimeregistration) {
                this.currentTimeRegistrationLocal = _.cloneDeep(newTimeregistration);

                if (
                    this.currentTimeRegistrationLocal &&
                    this.currentTimeRegistrationLocal.start &&
                    this.currentTimeRegistrationLocal.end
                ) {
                    const start = this.currentTimeRegistrationLocal.start;

                    this.currentTimeRegistrationLocal.start = dayjs(start).format('HH:mm');
                    this.currentTimeRegistrationLocal.end = dayjs(this.currentTimeRegistrationLocal.end).format(
                        'HH:mm'
                    );

                    this.$set(this.currentTimeRegistrationLocal, 'date', dayjs(start).format('YYYY-MM-DD'));
                }
            }
        }
    },

    methods: {
        ...mapActions('timeregistrations', ['updateTimeregistration', 'newTimeregistrationEvent']),

        async handleSubmit() {
            try {
                this.loading = true;

                await this.updateTimeregistration(timeWithoutMask(this.currentTimeRegistrationLocal));

                notify.call(this, {
                    title: this.$t('addTimeRegistration.succesfuly_saved'),
                    text: this.$t('addTimeRegistration.succesfuly_saved')
                });

                // Close bottom sheet when submit successful.
                this.$nextTick(() => {
                    this.$refs.form.reset();
                    this.isAddTimeRegistrationOpen = false;
                });
                this.newTimeregistrationEvent();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        endGreaterThanStart() {
            return (
                this.currentTimeRegistrationLocal.end > this.currentTimeRegistrationLocal.start ||
                this.$t('validation.end_greater_than_start')
            );
        },
        searchProject() {
            this.$refs.selectTableComponent.open();
        },
        handleSelectedProject(project) {
            this.currentTimeRegistrationLocal.project = project;
        },
        openCreateProjectModal() {
            this.isCreatingNewProject = true;
            this.isAddProjectOpen = true;
        }
    }
};
</script>
