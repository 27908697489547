<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <div class="page">
                <v-card outlined class="card-styling pa-4 mb-6">
                    <v-row>
                        <v-col cols="12" class="pa-8">
                            <p class="text-center">
                                We zijn volop bezig met het ontwikkelen van deze functionaliteit. We houden je op de
                                hoogte wanneer deze volledig af is!
                            </p>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
        </template>
    </view-layout>
</template>

<script>
// --- Components ---
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';

export default {
    components: { PageHeader, ViewLayout }
};
</script>

<style lang="scss"></style>
