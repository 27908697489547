<template>
    <base-bottom-sheet v-if="isOpen" v-model="isOpen" class="select-bottomsheet">
        <template #header>
            <bottom-sheet-header :button-text="addButtonText" :show-add="true" @new-item="handleClickNew">
                <base-icon-right v-if="i18nPath" :i18n-path="i18nPath">
                    {{ title }}
                </base-icon-right>
                <template v-else>
                    {{ title }}
                </template>
            </bottom-sheet-header>
        </template>
        <template #content>
            <component
                :is="inputComponent"
                ref="inputComponent"
                clickable
                v-bind="$attrs"
                @click-row="handleClickRow"
            />
        </template>
    </base-bottom-sheet>
</template>

<script>
import BaseIconRight from './BaseIconRight.vue';
import BottomSheetHeader from './BottomSheets/BottomSheetHeader.vue';

export default {
    components: {
        BottomSheetHeader,
        BaseIconRight
    },
    inheritAttrs: false,

    props: {
        inputComponent: {
            required: true
        },
        title: {
            required: true,
            type: String
        },
        addButtonText: {
            type: String,
            default: null
        },
        i18nPath: {
            type: String,
            default: null
        },
        reloadDataMethodName: {
            type: String,
            required: false
        }
    },

    data() {
        return {
            isOpen: false
        };
    },

    methods: {
        open() {
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
        },
        handleClickRow(item) {
            this.$emit('selected-item', item);
            this.close();
        },
        handleClickNew() {
            this.$emit('new-item');
        },
        refreshData() {
            if (this.reloadDataMethodName) {
                this.$refs.inputComponent[this.reloadDataMethodName]();
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
