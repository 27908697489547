<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                    <sub>
                        <tooltip :underlined="false" i18n-path="tooltips.timeregistrationProject.title"
                            ><template slot="activator"> <v-icon small> mdi-information </v-icon></template></tooltip
                        >
                    </sub>
                </template>

                <template #menu>
                    <v-btn class="mr-4" color="primary" @click="openInvoiceTimeregistrationWizard">
                        {{ $t('timeRegistration.to_invoice') }}
                    </v-btn>
                    <v-btn class="mr-4" color="primary" :to="{ name: 'addTimeRegistration' }">
                        {{ $t('timeRegistration.create_timeregistration') }}
                    </v-btn>
                </template></page-header
            >
        </template>
        <template #content>
            <TableCard>
                <template #content>
                    <time-registrations-table />
                </template>
            </TableCard>
            <create-invoice-from-timeregistration-wizard v-if="currentUserData" />
            <edit-time-registration v-if="currentUserData" />
        </template>
    </view-layout>
</template>

<script>
// --- Modules ---
// --- State ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import TimeRegistrationsTable from '@/components/DataTables/TimeRegistrationsTable.vue';
import CreateInvoiceFromTimeregistrationWizard from '@/components/BottomSheets/CreateInvoiceFromTimeregistrationWizard';
// --- Mixins ---
import EditTimeRegistration from '@/components/BottomSheets/EditTimeRegistration.vue';
import TableCard from '@/components/TableCard';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader';

export default {
    components: {
        CreateInvoiceFromTimeregistrationWizard,
        TableCard,
        TimeRegistrationsTable,
        EditTimeRegistration,
        ViewLayout,
        PageHeader
    },

    data() {
        return {
            projects: [],
            loading: false
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    created() {
        this.fetchAllProjects();
    },

    methods: {
        ...mapActions('projects', ['getProjects']),
        ...mapActions('bottomSheets', ['openInvoiceTimeregistrationWizard']),
        ...mapActions(['stopLoading', 'startLoading']),
        fetchAllProjects() {
            this.startLoading();
            this.getProjects()
                .then((result) => {
                    this.projects = result;
                })
                .finally(() => {
                    this.stopLoading();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
/* --- Override the default list behaviour for active childs --- */
.v-list {
    & :deep() {
        .v-list-group.active-child > .v-list-group__header {
            color: $color-secondary !important;
            caret-color: $color-secondary !important;

            .v-icon {
                color: $color-secondary !important;
            }
        }
        .v-list-group__header.active-child {
            color: $color-secondary !important;
            caret-color: $color-secondary !important;
        }
    }
}
</style>
