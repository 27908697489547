<template>
    <div class="billed_income-table">
        <template v-if="areDependenciesLoaded">
            <v-row class="filtering-container">
                <v-col cols="12" md="3">
                    <v-select
                        v-model="query.status"
                        :items="statusOptions"
                        :label="$t('general.status')"
                        item-text="label"
                        item-value="value"
                        clearable
                        hide-details
                        dense
                        outlined
                    />
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        v-model="query.projectId"
                        :items="projects"
                        :label="$t('general.projects')"
                        item-text="name"
                        item-value="id"
                        clearable
                        hide-details
                        dense
                        outlined
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <pagination-search v-model="query.q" />
                </v-col>
            </v-row>
            <pagination-data-table
                v-if="timeregistrations.data"
                ref="paginationDataTable"
                :headers="headers"
                :pagination="pagination"
                :pagination-data="timeregistrations"
                :loading="loading"
                sort-by="date"
                @refetch-data="$_handlePaginationRefresh"
                @sort-by="$_filtering_handleSortColumn"
                @sort-desc="$_filtering_handleSortDirectionDesc"
            >
                <template #[`item.start`]="{ item }"> {{ hourMinuteRepresentation(item.start) }} </template>
                <template #[`item.end`]="{ item }"> {{ hourMinuteRepresentation(item.end) }} </template>
                <template #[`item.date`]="{ item }"> {{ dateRepresentation(item.start) }} </template>
                <template #[`item.duration`]="{ item }">
                    {{ stringRepresentationDuration(item.start, item.end) }}
                </template>
                <template #[`item.invoiced`]="{ item }">
                    <chip v-if="item.invoiceId" color="green">
                        {{ $t('timeRegistration.table.invoiced') }}
                    </chip>
                    <chip v-if="!item.invoiceId" color="red">
                        {{ $t('timeRegistration.table.open') }}
                    </chip></template
                >
                <template #[`item.actions`]="{ item }">
                    <v-menu bottom left>
                        <template #activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>

                        <v-card>
                            <v-list dense>
                                <v-list-item v-if="item.invoiceId === null" @click="editTimeregistration(item)">
                                    <v-list-item-title>{{ $t('general.edit') }}</v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    v-if="item.invoiceId === null"
                                    @click="createInvoiceFromTimeregistration(item.id)"
                                >
                                    <v-list-item-title>{{ $t('general.create_invoice') }}</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click.stop="
                                        {
                                            dialog = true;
                                            selectedTimeregistration = item.id;
                                        }
                                    "
                                >
                                    <v-list-item-title>{{ $t('general.delete') }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                </template>
            </pagination-data-table>

            <confirmation-dialog
                v-if="dialog"
                v-model="dialog"
                :title="$t('general.delete')"
                :message="$t('general.confirm_delete_message')"
                :loading="loading"
                :negative-button="$t('general.cancel')"
                :positive-button="$t('general.delete')"
                @click-positive="removeTimeregistration"
                @click-negative="dialog = false"
            />
        </template>
    </div>
</template>

<script>
// --- State ---
import { mapState, mapActions } from 'vuex';
// --- Components ---
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';
import Chip from '../Chip';
import PaginationSearch from '../PaginationSearch.vue';
// --- Helper ---
import { mapFields } from 'vuex-map-fields';
import { stringRepresentationDuration } from '@/helpers/timeregistrationCalculation';
import { notify } from '@/helpers/successNotification';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { filteringMixin } from '../../mixins/filtering';
import PaginationDataTable from './PaginationDataTable.vue';
import dayjs from 'dayjs';

export default {
    components: {
        ConfirmationDialog,
        Chip,
        PaginationSearch,
        PaginationDataTable
    },
    mixins: [filteringMixin],

    data() {
        const sortableColumns = ['date'];

        return {
            headers: [
                {
                    text: this.$t('general.description'),
                    align: 'start',
                    value: 'description',
                    sortable: sortableColumns.includes('description')
                },
                {
                    text: this.$t('general.company'),
                    value: 'project.customer.company.name',
                    sortable: sortableColumns.includes('project.customer.company.name')
                },
                {
                    text: this.$t('general.project'),
                    value: 'project.name',
                    sortable: sortableColumns.includes('project.name')
                },
                {
                    text: this.$t('timeRegistration.start'),
                    value: 'start',
                    sortable: sortableColumns.includes('start')
                },
                {
                    text: this.$t('timeRegistration.end'),
                    value: 'end',
                    sortable: sortableColumns.includes('end')
                },
                {
                    text: this.$t('general.date'),
                    value: 'date',
                    sortable: sortableColumns.includes('date')
                },
                {
                    text: this.$t('general.status'),
                    value: 'invoiced',
                    sortable: sortableColumns.includes('invoiced')
                },
                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            dialog: false,
            loading: false,
            toDeleteUid: '',
            selectedTimeregistration: '',
            timeregistrations: {
                data: []
            },
            query: {
                status: null,
                type: null,
                projectId: null
            },
            statusOptions: [
                {
                    label: this.$t('general.invoiced'),
                    value: 'invoiced'
                },
                {
                    label: this.$t('general.not_invoiced'),
                    value: 'notInvoiced'
                }
            ],
            sortableColumns
        };
    },

    async created() {
        this.loading = true;
        Promise.all([this.wrapperFunction(), this.getProjects()]).finally(() => {
            this.loading = false;
        });
    },

    computed: {
        ...mapState('timeregistrations', ['newTimeregistrationEvents']),
        ...mapState('projects', ['projects']),
        ...mapFields('bottomSheets', [
            'isAddTimeRegistrationOpen',
            'currentTimeRegistration',
            'isCreatingNewTimeRegistration'
        ]),
        areDependenciesLoaded() {
            return Boolean(this.projects);
        }
    },

    watch: {
        newTimeregistrationEvents: {
            deep: true,
            handler: function () {
                this.wrapperFunction();
            }
        }
    },

    methods: {
        ...mapActions('bottomSheets', ['editTimeregistration', 'newTimeRegistration']),
        ...mapActions('timeregistrations', [
            'deleteTimeregistration',
            'getAllTimeregistrationsPagination',
            'newTimeregistrationEvent',
            'timeregistrationToInvoice'
        ]),
        ...mapActions('projects', ['getProjects']),
        stringRepresentationDuration,
        async wrapperFunction() {
            return this.getAllTimeregistrationsPagination({
                pagination: {
                    force: true,
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit
                },
                query: {
                    ...this.query,
                    sortDesc: this.query.sortDesc,
                    sortByColumn: this.query.sortByColumn === 'date' ? 'start' : this.query.sortByColumn
                }
            }).then((response) => {
                response.data = response.data.filter((_timeregistration) => {
                    return _timeregistration.end !== null;
                });

                this.timeregistrations = response;
            });
        },
        async createInvoiceFromTimeregistration(timeregistrationId) {
            this.loading = true;
            try {
                const invoice = await this.timeregistrationToInvoice(timeregistrationId);

                notify.call(this, {
                    title: this.$t('addTimeRegistration.succesfuly_invoice_created'),
                    text: this.$t('addTimeRegistration.succesfuly_invoice_created')
                });

                // Redirect to draft invoice
                this.$router.push(`/edit-invoice/${invoice.id}`);
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
                this.dialog = false;
            }
        },
        async removeTimeregistration() {
            this.loading = true;
            try {
                await this.deleteTimeregistration(this.selectedTimeregistration);

                notify.call(this, {
                    title: this.$t('addTimeRegistration.succesfuly_deleted'),
                    text: this.$t('addTimeRegistration.succesfuly_deleted')
                });

                this.newTimeregistrationEvent();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
                this.dialog = false;
            }
        },
        hourMinuteRepresentation(datetime) {
            return dayjs(datetime).format('HH:mm');
        },
        dateRepresentation(datetime) {
            return dayjs(datetime).format('DD-MM-YYYY');
        }
    }
};
</script>
