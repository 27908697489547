import dayjs from 'dayjs';
import _ from 'lodash';

export const timeWithoutMask = (timeregistration) => {
    const copy = _.cloneDeep(timeregistration);
    copy.projectId = copy.project.id;

    const startHour = copy.start.split(':')[0];
    const startMinutes = copy.start.split(':')[1];
    const startTime = dayjs.tz(`${copy.date} ${startHour}:${startMinutes}`, 'YYYY-MM-DD H:m', 'Europe/Brussels');
    copy.start = startTime;

    if (copy.end) {
        const endHour = copy.end.split(':')[0];
        const endMinutes = copy.end.split(':')[1];
        const endTime = dayjs.tz(`${copy.date} ${endHour}:${endMinutes}`, 'YYYY-MM-DD H:m', 'Europe/Brussels');
        copy.end = endTime;
    }

    return copy;
};
