<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <v-card outlined class="pb-0">
                <v-card-text v-if="currentUserData">
                    <v-form id="timeregistrationForm" ref="form" v-model="valid">
                        <v-row class="ma-0">
                            <v-col cols="12" md="12">
                                <date-field
                                    id="date"
                                    v-model="date"
                                    :label="$t('general.date')"
                                    outlined
                                    dense
                                    :hint="$t('addIncome.add_date_invoice')"
                                    persistent-hint
                                    required
                                />
                            </v-col>
                        </v-row>
                        <v-row class="mx-1">
                            <v-col cols="12" md="12">
                                <v-simple-table id="addTimeregistrationTable" dense>
                                    <template #default>
                                        <thead>
                                            <th scope="col" class="text-left">
                                                {{ $t('general.project') }}
                                            </th>
                                            <th scope="col" class="text-left">
                                                {{ $t('timeRegistration.form.description') }}
                                            </th>
                                            <th scope="col" class="text-left">
                                                {{ $t('timeRegistration.start') }}
                                            </th>
                                            <th scope="col" class="text-left">
                                                {{ $t('timeRegistration.end') }}
                                            </th>
                                            <th scope="col" class="text-right"></th>
                                        </thead>
                                        <tbody>
                                            <template v-for="(timeregistration, index) in timeregistrations">
                                                <tr :key="index" :row-index="index" class="ma-0">
                                                    <td>
                                                        <search-input
                                                            :label="$t('timeRegistration.form.project')"
                                                            :value="
                                                                timeregistration.project
                                                                    ? timeregistration.project.name
                                                                    : ''
                                                            "
                                                            @clicked-search="searchProject(index)"
                                                        />
                                                    </td>

                                                    <td>
                                                        <text-field
                                                            v-model="timeregistration.description"
                                                            data-form="description"
                                                            name="projectDescription"
                                                            :label="$t('timeRegistration.form.description')"
                                                            hide-details="auto"
                                                            outlined
                                                            dense
                                                            :rules="nameRules"
                                                        ></text-field>
                                                    </td>
                                                    <td>
                                                        <text-field
                                                            v-model="timeregistration.start"
                                                            v-mask="timeMask"
                                                            data-form="start"
                                                            name="duration"
                                                            :label="$t('timeRegistration.start')"
                                                            placeholder="hh:mm"
                                                            outlined
                                                            dense
                                                            :rules="timeRangeRules"
                                                        ></text-field>
                                                    </td>
                                                    <td>
                                                        <text-field
                                                            v-model="timeregistration.end"
                                                            v-mask="timeMask"
                                                            data-form="end"
                                                            name="duration"
                                                            :label="$t('timeRegistration.end')"
                                                            placeholder="hh:mm"
                                                            outlined
                                                            dense
                                                            :rules="[
                                                                ...timeRangeRules,
                                                                endGreaterThanStart(
                                                                    timeregistration.start,
                                                                    timeregistration.end
                                                                )
                                                            ]"
                                                        ></text-field>
                                                    </td>
                                                    <td>
                                                        <v-btn icon color="primary" @click="removeItem(index)">
                                                            <v-icon small> mdi-delete </v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </template>
                                            <tr>
                                                <td class="add-item-td" colspan="8">
                                                    <v-btn
                                                        small
                                                        outlined
                                                        data-action="add-item"
                                                        @click.stop="addNewItem"
                                                    >
                                                        <v-icon small> mdi-plus </v-icon>
                                                        Voeg een item toe
                                                    </v-btn>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="add-item-td" colspan="8">
                                                    <submit-button
                                                        id="saveAndNew"
                                                        data-action="create-timeregistration-and-new"
                                                        color="primary"
                                                        class="mr-3"
                                                        :disabled="!valid || timeregistrations.length === 0"
                                                        @click="handleSubmit"
                                                        >{{ $t('timeRegistration.form.save_and-new')
                                                        }}<v-icon right dense dark
                                                            >mdi-chevron-right</v-icon
                                                        ></submit-button
                                                    >
                                                    <submit-button
                                                        id="saveAndOverview"
                                                        data-action="create-timeregistration-and-overview"
                                                        color="primary"
                                                        :disabled="!valid || timeregistrations.length === 0"
                                                        @click="handleSubmitAndOverview"
                                                        >{{ $t('timeRegistration.form.save_and_overview')
                                                        }}<v-icon right dense dark
                                                            >mdi-chevron-right</v-icon
                                                        ></submit-button
                                                    >
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-card v-if="currentUserData" outlined> </v-card>

                    <loader v-if="loading" />
                    <select-table-component
                        ref="selectTableComponent"
                        :title="$t('general.select_project')"
                        :input-status="'active'"
                        :input-component="projectsTable"
                        @selected-item="handleSelectedProject"
                        @new-item="newProject"
                    />
                </v-card-text>
            </v-card> </template
    ></view-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { notify } from '@/helpers/successNotification';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
// --- Components ---
import Loader from '@/components/Loader';
import { createTimeregistration } from '../models/timeregistration';
import { timeWithoutMask } from '@/helpers/timeregistration';
import dayjs from 'dayjs';
import ViewLayout from '@/components/ViewLayout';
import PageHeader from '@/components/PageHeader';

import SearchInput from '@/components/SearchInput.vue';
import ProjectsTable from '@/components/DataTables/ProjectsTable.vue';
import SelectTableComponent from '@/components/SelectTableComponent.vue';
import SubmitButton from '@/components/SubmitButton.vue';

export default {
    components: { Loader, ViewLayout, PageHeader, SearchInput, SelectTableComponent, SubmitButton },
    mixins: [formRulesMixin],

    data() {
        return {
            currentTab: 0,
            valid: false,
            date: dayjs().format('YYYY-MM-DD'),
            loading: false,
            timeregistrations: [],
            projectsTable: ProjectsTable,
            selectedTimregistrationIndexForInsertingProject: null
        };
    },

    beforeMount() {
        this.addNewItem();
    },

    mounted() {
        this.$refs.form.resetValidation();
    },

    computed: {
        ...mapState(['selectedYear']),
        ...mapState('auth', ['currentUserData'])
    },

    methods: {
        ...mapActions('timeregistrations', ['createTimeregistrations', 'newTimeregistrationEvent']),
        ...mapActions('bottomSheets', ['newProject']),
        ...mapActions(['startLoading', 'stopLoading']),
        timeMask(value) {
            const hours = [/[0-2]/, value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/];
            const minutes = [/[0-5]/, /[0-9]/];
            return value.length > 2 ? [...hours, ':', ...minutes] : hours;
        },
        async handleSubmit() {
            try {
                this.loading = true;

                await this.createTimeregistrations(
                    this.mergeTimeregistrationAndDate(this.timeregistrations, this.date)
                );

                notify.call(this, {
                    title: this.$t('addTimeRegistration.succesfuly_saved'),
                    text: this.$t('addTimeRegistration.succesfuly_saved')
                });

                this.newTimeregistrationEvent();
                this.timeregistrations = [];
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        async handleSubmitAndOverview() {
            try {
                this.handleSubmit();
                this.$router.push({
                    name: 'timeRegistration'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        mergeTimeregistrationAndDate(timeregistrations, date) {
            return timeregistrations.map((timeregistration) => {
                return timeWithoutMask({
                    ...timeregistration,
                    date
                });
            });
        },
        addNewItem() {
            const newItem = createTimeregistration();
            this.timeregistrations.push({ ...newItem });
        },
        removeItem(index) {
            if (this.timeregistrations.length > 0) {
                this.timeregistrations.splice(index, 1);
            }
        },
        endGreaterThanStart(start, end) {
            return end > start || this.$t('validation.end_greater_than_start');
        },
        searchProject(timeregistrationIndex) {
            this.selectedTimregistrationIndexForInsertingProject = timeregistrationIndex;
            this.$refs.selectTableComponent.open();
        },
        handleSelectedProject(project) {
            this.timeregistrations[this.selectedTimregistrationIndexForInsertingProject].project = project;
        }
    }
};
</script>
<style lang="scss">
#addTimeregistrationTable {
    table {
        th {
            padding: 5px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }
        td {
            padding: 20px 10px;
        }
    }
}

tbody {
    tr:hover {
        background-color: transparent !important;
    }
}

td.td-action {
    vertical-align: baseline;
}

td.no-items-td {
    padding: 20px !important;
}

td.add-item-td {
    padding: 10px !important;
}
</style>
