var render = function render(){var _vm=this,_c=_vm._self._c;return _c('view-layout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('page-header',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.title))+" ")]},proxy:true}])})]},proxy:true},{key:"content",fn:function(){return [_c('v-card',{staticClass:"pb-0",attrs:{"outlined":""}},[(_vm.currentUserData)?_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"id":"timeregistrationForm"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('date-field',{attrs:{"id":"date","label":_vm.$t('general.date'),"outlined":"","dense":"","hint":_vm.$t('addIncome.add_date_invoice'),"persistent-hint":"","required":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-row',{staticClass:"mx-1"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-simple-table',{attrs:{"id":"addTimeregistrationTable","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('general.project'))+" ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('timeRegistration.form.description'))+" ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('timeRegistration.start'))+" ")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('timeRegistration.end'))+" ")]),_c('th',{staticClass:"text-right",attrs:{"scope":"col"}})]),_c('tbody',[_vm._l((_vm.timeregistrations),function(timeregistration,index){return [_c('tr',{key:index,staticClass:"ma-0",attrs:{"row-index":index}},[_c('td',[_c('search-input',{attrs:{"label":_vm.$t('timeRegistration.form.project'),"value":timeregistration.project
                                                                ? timeregistration.project.name
                                                                : ''},on:{"clicked-search":function($event){return _vm.searchProject(index)}}})],1),_c('td',[_c('text-field',{attrs:{"data-form":"description","name":"projectDescription","label":_vm.$t('timeRegistration.form.description'),"hide-details":"auto","outlined":"","dense":"","rules":_vm.nameRules},model:{value:(timeregistration.description),callback:function ($$v) {_vm.$set(timeregistration, "description", $$v)},expression:"timeregistration.description"}})],1),_c('td',[_c('text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.timeMask),expression:"timeMask"}],attrs:{"data-form":"start","name":"duration","label":_vm.$t('timeRegistration.start'),"placeholder":"hh:mm","outlined":"","dense":"","rules":_vm.timeRangeRules},model:{value:(timeregistration.start),callback:function ($$v) {_vm.$set(timeregistration, "start", $$v)},expression:"timeregistration.start"}})],1),_c('td',[_c('text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.timeMask),expression:"timeMask"}],attrs:{"data-form":"end","name":"duration","label":_vm.$t('timeRegistration.end'),"placeholder":"hh:mm","outlined":"","dense":"","rules":[
                                                            ..._vm.timeRangeRules,
                                                            _vm.endGreaterThanStart(
                                                                timeregistration.start,
                                                                timeregistration.end
                                                            )
                                                        ]},model:{value:(timeregistration.end),callback:function ($$v) {_vm.$set(timeregistration, "end", $$v)},expression:"timeregistration.end"}})],1),_c('td',[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1)])]}),_c('tr',[_c('td',{staticClass:"add-item-td",attrs:{"colspan":"8"}},[_c('v-btn',{attrs:{"small":"","outlined":"","data-action":"add-item"},on:{"click":function($event){$event.stopPropagation();return _vm.addNewItem.apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Voeg een item toe ")],1)],1)]),_c('tr',[_c('td',{staticClass:"add-item-td",attrs:{"colspan":"8"}},[_c('submit-button',{staticClass:"mr-3",attrs:{"id":"saveAndNew","data-action":"create-timeregistration-and-new","color":"primary","disabled":!_vm.valid || _vm.timeregistrations.length === 0},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.$t('timeRegistration.form.save_and-new'))),_c('v-icon',{attrs:{"right":"","dense":"","dark":""}},[_vm._v("mdi-chevron-right")])],1),_c('submit-button',{attrs:{"id":"saveAndOverview","data-action":"create-timeregistration-and-overview","color":"primary","disabled":!_vm.valid || _vm.timeregistrations.length === 0},on:{"click":_vm.handleSubmitAndOverview}},[_vm._v(_vm._s(_vm.$t('timeRegistration.form.save_and_overview'))),_c('v-icon',{attrs:{"right":"","dense":"","dark":""}},[_vm._v("mdi-chevron-right")])],1)],1)])],2)]},proxy:true}],null,false,573955755)})],1)],1)],1),(_vm.currentUserData)?_c('v-card',{attrs:{"outlined":""}}):_vm._e(),(_vm.loading)?_c('loader'):_vm._e(),_c('select-table-component',{ref:"selectTableComponent",attrs:{"title":_vm.$t('general.select_project'),"input-status":'active',"input-component":_vm.projectsTable},on:{"selected-item":_vm.handleSelectedProject,"new-item":_vm.newProject}})],1):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }