import dayjs from 'dayjs';

export function stringRepresentationDuration(begin, end) {
    if (end < begin) {
        throw new Error('BeginLargerThanEnd');
    }

    const numberOfMinutes = calculateNumberOfMinutes(begin, end);

    const hours = Math.floor(numberOfMinutes / 60);
    const minutes = numberOfMinutes % 60;

    return `${hours}u ${minutes}m`;
}

export function calculateNumberOfHours(start, end) {
    isEndBeforeStart(start, end);

    const numberOfMinutesInHour = 60;
    return calculateNumberOfMinutes(start, end) / numberOfMinutesInHour;
}

export function calculateNumberOfMinutes(start, end) {
    isEndBeforeStart(start, end);

    return dayjs(end).diff(dayjs(start), 'minute');
}

function isEndBeforeStart(start, end) {
    if (dayjs(end).isSameOrBefore(start)) {
        throw new Error('startMustBeBeforeEnd');
    }
}
