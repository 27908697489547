<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <TableCard class="px-3 mb-5 filterBar" :rounded="false">
                <template #content>
                    <v-row>
                        <v-col cols="12" lg="3">
                            <date-field v-model="selectedDate" :label="$t('timeRegistration.report.begin')" />
                        </v-col>
                    </v-row>
                </template>
            </TableCard>

            <TableCard :rounded="false">
                <template #content>
                    <v-row>
                        <v-col cols="12" class="text-center">Totaal: {{ totalWorkedHours }} uur </v-col>
                    </v-row>
                </template>
            </TableCard>
            <TableCard :rounded="false">
                <template #content>
                    <vue-apex-charts
                        v-if="chartOptions.xaxis.categories.length > 0"
                        type="bar"
                        height="350"
                        :options="chartOptions"
                        :series="series"
                    />
                </template>
            </TableCard>
        </template>
    </view-layout>
</template>

<script>
// --- Modules ---
// --- State ---
import { mapState, mapActions } from 'vuex';
// --- Components ---
// --- Mixins ---
import TableCard from '@/components/TableCard.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import VueApexCharts from 'vue-apexcharts';
import dayjs from 'dayjs';

export default {
    components: {
        TableCard,
        ViewLayout,
        PageHeader,
        VueApexCharts
    },

    data() {
        return {
            totalWorkedHours: 0,
            loading: false,

            selectedDate: dayjs().format('YYYY-MM-DD'),
            series: [],
            chartOptions: {
                colors: ['#21005C'],
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: false
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '60%',
                        dataLabels: {
                            position: 'top' // top, center, bottom
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'middle',
                    formatter: (value) => {
                        if (!value) {
                            return;
                        }
                        return `${this.msToTime(this.hoursToMs(value))}`;
                    },
                    offsetY: -20,
                    align: 'center',
                    style: {
                        fontSize: '12px',
                        colors: ['#21005C']
                    }
                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    title: {
                        text: 'Uur'
                    },
                    labels: {
                        formatter: (value) => {
                            return `${value}h`;
                        }
                    }
                },
                tooltip: {
                    enabled: false,
                    y: {
                        formatter: (hours) => {
                            return `${this.hourToTime(hours)}`;
                        }
                    }
                }
            }
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    watch: {
        selectedDate() {
            this.fetchTimeregistrationSummary();
        }
    },

    created() {
        this.fetchTimeregistrationSummary();
    },

    methods: {
        ...mapActions('timeregistrations', ['getTimeregistrationSummary']),
        ...mapActions(['stopLoading', 'startLoading']),
        fetchTimeregistrationSummary() {
            this.startLoading();
            this.getTimeregistrationSummary({ beginDate: this.selectedDate })
                .then((dateMap) => {
                    const categories = [
                        ...Object.keys(dateMap).map((_date) => {
                            return dayjs(_date, 'YYYY-MM-DD').format('DD-MM-YYYY');
                        })
                    ].sort((a, b) => {
                        const dateA = dayjs(a, 'DD-MM-YYYY');
                        const dateB = dayjs(b, 'DD-MM-YYYY');

                        if (dateA.isBefore(dateB)) {
                            return -1;
                        }

                        return 1;
                    });

                    this.chartOptions = {
                        ...this.chartOptions,
                        xaxis: {
                            categories
                        }
                    };

                    this.totalWorkedHours = 0;
                    this.series = [
                        {
                            name: 'dates',
                            data: this.chartOptions.xaxis.categories.map((_date) => {
                                const hours = this.msToHours(dateMap[dayjs(_date, 'DD-MM-YYYY').format('YYYY-MM-DD')]);
                                this.totalWorkedHours += hours;
                                return hours;
                            })
                        }
                    ];
                })
                .finally(() => {
                    this.stopLoading();
                });
        },
        msToTime(duration) {
            let minutes = Math.floor((duration / (1000 * 60)) % 60),
                hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;

            return hours + ':' + minutes;
        },
        msToHours(duration) {
            if (!duration) {
                return 0;
            }

            let hours = (duration / (1000 * 60 * 60)) % 24;

            return hours;
        },
        hourToTime(hour) {
            return this.msToTime(this.hoursToMs(hour));
        },
        hoursToMs(hour) {
            return hour * 3600000;
        }
    }
};
</script>

<style lang="scss" scoped>
/* --- Override the default list behaviour for active childs --- */
.filterBar {
    background-color: #fff;
    border: 1px solid $color-border;
}
.summaryBar .col {
    background-color: #fff;
    border-top: 1px solid $color-border;
    border-left: 1px solid $color-border;
    border-right: 1px solid $color-border;
    text-align: center;
}
</style>
